import React, { useState } from 'react'
import "./wheel.css";
import { useParams } from 'react-router-dom';

const WhhelChocodate = ({ items, onWinItemChange, winCount }) => {
  const { qrCode } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [rotationCount, setRotationCount] = useState(100);
  const [isProcessing, setIsProcessing] = useState(false);
  const [previousItem, setPreviousItem] = useState(0);
  const [winItem, setWinItem] = useState("");
  const [hasSpun, setHasSpun] = useState(false);


  // console.log(winCount);

  // const [reversedItems, setReversedItems] = useState();

  const [startTime, setStartTime] = useState(0);
  let durationPerOneRotation = 0.1; // the status depends heavily on the value of durationPerOneRotation: for example, if 20 => not correct
  let spinning = selectedItem !== null ? "spinning" : "";

  const handleLottery = async () => {
    setStartTime(Date.now());
    try {
      setIsProcessing(true);
      let _selectedItem = 0;
      setSelectedItem(selectedItem);

      setTimeout(() => {
        setSelectedItem(_selectedItem);
        setRotationCount(100000);
      }, 500);
      // }
    } catch (err) {
      console.log("err", err);
    }
  };

  const wheelVars = {
    "--nb-item": items?.length,
    "--selected-item": selectedItem,
    "--nb-turn": rotationCount,
    "--spinning-duration": `calc(${rotationCount} * ${durationPerOneRotation}s)`,
  };

  const handleSetRotationCount = () => {
    const elapsedTime = (Date.now() - startTime) / 1000;
    let passedItems =
      (elapsedTime / durationPerOneRotation) * 8 -
      Math.floor(elapsedTime / durationPerOneRotation) * 8;
    let exactPassedItems = Math.floor(passedItems);

    setRotationCount(0.1);
    let itemWhenClickedStopButton = 9 - exactPassedItems + previousItem;
    if (itemWhenClickedStopButton > 8) itemWhenClickedStopButton -= 8;
    setSelectedItem(itemWhenClickedStopButton);

    setTimeout(() => {
      setRotationCount(3);
      let selected;

      // Determine winning item based on winCount
      if (winCount > 0 && winCount % 35000 === 0) {
        // Win "GOLD COIN"
        selected = items.findIndex((item) => item.name === "GOLD COIN");
      } else if (winCount > 0 && winCount % 35000 === 0) {
        // Win "Mobile Phone"
        selected = items.findIndex((item) => item.name === "Mobile Phone");
      } else if (winCount > 0 && winCount % 5000 === 0) {
        // Win "2 Movie Tickets"
        selected = items.findIndex((item) => item.name === "2 Movie Ticket");
      } else if (winCount > 0 && winCount % 700 === 0) {
        // Win "1 Free Family pack"
        selected = items.findIndex(
          (item) => item.name === "1 Free Family pack"
        );
      } else {
        // Default to "50% Off on next order"
        selected = items.findIndex(
          (item) => item.name === "50% Off on next order"
        );
      }

      setSelectedItem(selected);
      setPreviousItem(selected);
      const newWinItem = items[selected];
      setWinItem(newWinItem);
      if (onWinItemChange) {
        onWinItemChange(newWinItem); // Notify parent of the new winItem
      }
      setIsProcessing(false);
    }, 5);
  };

  return (
    items &&
    items.length > 0 && (
      <>
        <div className="wheel_wrp">
          <div className="wheel_wrp_head">
            <img src={require("../../img/chocodate.webp")} alt="" />
            <div className="_gift">
              <img src={require("../../img/gift.png")} alt="" />
            </div>
          </div>
          <div className="border">
            <div className="wheel-container">
              <div className={`wheel ${spinning}`} style={wheelVars}>
                {items.map((item, index) => (
                  <div
                    className="choco_wheel-item"
                    key={index}
                    style={{ "--item-nb": index }}
                  >
                    <img
                      src={item.img}
                      // alt={item.name}
                      style={{
                        width: "80px",
                        height: "auto",
                        marginLeft: "10px",
                      }}
                    />
                    {/* <span>{item.name}</span> */}
                  </div>
                ))}
              </div>
              <div className="__logo">
                <img src={require("../../img/chocodate.png")} alt="" />
              </div>
              <div className="border_img">
                {isProcessing ? (
                  <img src={require("../../img/choco.gif")} alt="" />
                ) : (
                  <img src={require("../../img/b_order.png")} alt="" />
                )}
              </div>
              <div className="blackBorder"></div>
            </div>
          </div>
          <div className="wrp_btn">
            {isProcessing ? (
              // <img src={require("../../img/b_utton.png")} alt="" />
              <img src={require("../../img/__button.gif")} alt="" />
            ) : (
              <img src={require("../../img/__button.gif")} alt="" />
            )}
            {!isProcessing ? (
              <span
                className="sp_btn"
                onClick={() => {
                  if (!hasSpun) {
                    handleLottery();
                    setIsProcessing(true);
                    setHasSpun(true); // Ensure only one spin per session
                  }
                }}
                disabled={isProcessing || hasSpun}
              >
                <h2>Spin & Win</h2>
              </span>
            ) : (
              <span
                className="sp_btn"
                onClick={() => {
                  handleSetRotationCount();
                  setIsProcessing(false); // Allow stopping the spin
                }}
                disabled={!isProcessing}
              >
                <h2>Stop</h2>
              </span>
            )}
          </div>
          {/* <div className="_code">
            <p>
              Your Code: <strong>{qrCode}</strong>{" "}
            </p>
          </div> */}
        </div>
      </>
    )
  );
};

export default WhhelChocodate
