import { useContext } from "react";
import { VarifyContext } from "../context/VarifyContext.js";
import { Navigate } from "react-router-dom";

export const VarifyRoute = ({ children }) => {
  const { varifyCode } = useContext(VarifyContext);

//  const code = varifyCode;

//     // console.log(code);

  if (varifyCode === null || varifyCode === false) {
    return <Navigate to="/chocodate" />;
  }

  return children;
};
