import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import backimg from "../../img/red.jpg";
import backimgTwo from "../../img/center.jpg";
import ddfCode from "./DDF.js";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import "./Chocodate.scss";
import { VarifyContext } from "../../context/VarifyContext.js";

const Chocodate = () => {
  const { coupon, promo } = useParams();
  const [isValid, setValid] = useState(false);
  const { dispatch } = useContext(VarifyContext);
  const [backImg, setBackImg] = useState("");
  const [qrCode, setQrCode] = useState(coupon || "");
  const [link, setLink] = useState(promo || "");
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [qrData, setQrData] = useState("");
  const [scannedData, setScannedData] = useState("");
  const [splitData, setSplitData] = useState("");
  const [varifyCode, setVarifyCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // const devApiLink = "http://localhost:4047/api/v1";
  const devApiLink = "https://chocodate-api.notionspromos.com/api/v1";

  // console.log(apiResponse);

  useEffect(() => {
    const updateBackground = () => {
      if (window.innerWidth < 900) {
        setBackImg(backimgTwo);
      } else {
        setBackImg(backimg);
      }
    };

    // Initial call to set the correct image on component mount
    updateBackground();

    // Event listener to update the image on window resize
    window.addEventListener("resize", updateBackground);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateBackground);
  }, []);

  useEffect(() => {
    if (!qrCode || !link) {
      return;
    }

    const getVarifyCode = async () => {
      setLoading(true);
      try {
        await axios
          .get(`${devApiLink}/varifyCode/${qrCode}`)
          .then((response) => {
            const isCodeValid = response.data.success;
            dispatch({ type: "ADD_VARIFY", payload: isCodeValid });
            // new Promise((resolve) => setTimeout(resolve, 1000));
            setApiResponse(isCodeValid);
          });
      } catch (error) {
        setError("Code not valid or Already Used");
      } finally {
        setLoading(false);
      }
    };

    getVarifyCode();
  }, [qrCode, coupon, link]);

  const handelClear = () => {
    setApiResponse("");
  };

  //  console.log(statusCode());

  const navigate = useNavigate();

  // console.log(qrCode, link);
  const statusCode = () => {
    if (loading) {
      return <small>Loading...</small>;
    }

    if (apiResponse == true && promo == "3") {
      return <FaCheckCircle color="#39e75f" />;
    }

    if (apiResponse === false || (apiResponse === null && promo === "")) {
      return <RxCrossCircled color="#ed4337" />;
    }

    if (apiResponse === null && promo !== "") {
      return <p> - </p>;
    }

    return <RxCrossCircled color="#ed4337" />; // Default fallback
  };

  useEffect(() => {
    if (!qrCode || !link) {
      return;
    }
    if (apiResponse === true) {
      if (link === "3") {
        navigate(`/always-a-winner/${qrCode}`, { replace: true });
      }
    } else {
      setError("Code not valid or Already Used");
    }
  }, [coupon, promo, apiResponse, qrCode, link]);

  const hadlleSubmit = (e) => {
    e.preventDefault();
    if (apiResponse === true) {
      if (link === "3") {
        const timer = setTimeout(() => {
          navigate(`/always-a-winner/${qrCode}`, { replace: true });
        }, 2000);

        // Cleanup timeout if component unmounts before redirect
        return () => clearTimeout(timer);
      }
    } else {
      setError("Code not valid or Already Used");
    }
    // setQrCode("");
    // setLink("");
  };

  return (
    <div
      className="xl__home"
      style={{
        backgroundImage: `url(${backImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="home_wrp">
        <div className="__wheel" id="1">
          {/* <div className="_logo">
            <img src={require("../../img/chocodate_logo.webp")} alt="" />
          </div> */}
          <div className="back_img">
            <img src={require("../../img/wheel.gif")} alt="" />
          </div>
        </div>
        <div className="__info" id="2">
          <div className="_logo">
            <img src={require("../../img/chocodate_logo.webp")} alt="" />
          </div>
          <div className="_gift">
            <img src={require("../../img/gift.png")} alt="" />
          </div>
          <div>
            <div class="xl__brand__title">
              <span>Enter The Code and Win</span>
            </div>
            {apiResponse === false || (apiResponse === null && promo === "") ? (
              <div className="wrongCode" onClick={handelClear}>
                <p>
                  Code has been already used <RxCrossCircled color="white" />
                </p>
              </div>
            ) : (
              <form className="__form">
                <inputs>
                  <input
                    type="text"
                    placeholder="DFERSK56D"
                    maxLength="13"
                    required
                    id="code_two"
                    value={qrCode}
                    onChange={(e) => setQrCode(e.target.value)}
                  />
                  <div className="__space">{statusCode()}</div>
                  <input
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    required
                    type="text"
                    placeholder="1"
                    maxLength="8"
                  />
                  {/* <div className="__space">
                <p>-</p>
              </div> */}
                </inputs>
                {/* <QrGen/> */}
                {/* <button type="submit">ENTER</button> */}
                {/* <div className="err_msg">
              {error && <p>{error}</p>}

            </div> */}
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chocodate;
