import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import axios from "axios";
import Error from "./pages/error/Error";
import Sucsess from "./pages/sucsess/Sucsess";
import Register from "./pages/login/Register";
import Login from "./pages/login/Login";
import Footer from "./components/footer/Footer";
import { VarifyRoute } from "./util/VarifyRoute";
import Chocodate from "./pages/chocodate/Chocodate";
import ChocodateWheel from "./pages/btob/ChocodateWheel";

// axios.defaults.baseURL = "http://localhost:4348/api/v1";
// axios.defaults.baseURL = "https://chocodate-api.notionspromos.com/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Chocodate />} />
        <Route path="/always-a-winner/:coupon/:promo" element={<Chocodate />} />
        <Route path="*" element={<Chocodate />} />
        <Route path="/sucsess" element={<Sucsess />} />

        {/* <Route
          path="/"
          element={
            <VarifyRoute>
              <Chocodate />
            </VarifyRoute>
          }
        /> */}

        {/* <Route
          path="/always-a-winner/:coupon/:promo"
          element={
            <VarifyRoute>
              <Chocodate />
            </VarifyRoute>
          }
        /> */}
        <Route
          path="/always-a-winner/:qrCode"
          element={
            <VarifyRoute>
              <ChocodateWheel />
            </VarifyRoute>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
